module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://backend.magenable.com.au/graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":960000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

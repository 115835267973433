exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-b-2-b-bigcommerce-js": () => import("./../../../src/pages/b2b-bigcommerce.js" /* webpackChunkName: "component---src-pages-b-2-b-bigcommerce-js" */),
  "component---src-pages-b-2-b-magento-js": () => import("./../../../src/pages/b2b-magento.js" /* webpackChunkName: "component---src-pages-b-2-b-magento-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magenable-about-js": () => import("./../../../src/pages/magenable-about.js" /* webpackChunkName: "component---src-pages-magenable-about-js" */),
  "component---src-pages-magenable-contact-js": () => import("./../../../src/pages/magenable-contact.js" /* webpackChunkName: "component---src-pages-magenable-contact-js" */),
  "component---src-pages-magenable-services-js": () => import("./../../../src/pages/magenable-services.js" /* webpackChunkName: "component---src-pages-magenable-services-js" */),
  "component---src-pages-magenable-technology-partners-js": () => import("./../../../src/pages/magenable-technology-partners.js" /* webpackChunkName: "component---src-pages-magenable-technology-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/BlogPostPage.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-blog-posts-category-page-js": () => import("./../../../src/templates/BlogPostsCategoryPage.js" /* webpackChunkName: "component---src-templates-blog-posts-category-page-js" */),
  "component---src-templates-blog-posts-list-page-js": () => import("./../../../src/templates/BlogPostsListPage.js" /* webpackChunkName: "component---src-templates-blog-posts-list-page-js" */),
  "component---src-templates-blog-posts-tag-page-js": () => import("./../../../src/templates/BlogPostsTagPage.js" /* webpackChunkName: "component---src-templates-blog-posts-tag-page-js" */),
  "component---src-templates-case-studies-single-js": () => import("./../../../src/templates/CaseStudiesSingle.js" /* webpackChunkName: "component---src-templates-case-studies-single-js" */)
}

